// Desc: Constants used in the application

// User role constants
export const USER_ROLE_SUPERAGENT = "superAgent";
export const USER_ROLE_AGENT = "agent";
export const USER_ROLE_ENTERPRISE = "enterprise";
export const USER_ROLE_EMPLOYEE = "employee";
export const USER_ROLE_MEMBER = "member";
export const USER_ROLE_FINANCE = "finance";

export const SUPER_ROLE = USER_ROLE_SUPERAGENT || USER_ROLE_EMPLOYEE;
export const AGENT_ROLE = USER_ROLE_AGENT || USER_ROLE_MEMBER;

// Trip type constants
export const TRIP_TYPE_ONE_WAY = "oneWay";
export const TRIP_TYPE_ROUND_TRIP = "roundTrip";
export const TRIP_TYPE_MULTI_CITY = "multiCity";

// System fee constants
export const LOCAL_SYSTEM_FEE = 50;
export const INTERNATIONAL_SYSTEM_FEE = 330;

// Booking status constants
export const BOOKING_STATUS_PENDING = "PENDING";
export const BOOKING_STATUS_CONFIRMED = "CONFIRMED";
export const BOOKING_STATUS_CANCELLED = "CANCELLED";
export const BOOKING_STATUS_COMPLETED = "COMPLETED";

// Booking type constants
export const BOOKING_TYPE_LOCAL = "LOCAL";
export const BOOKING_TYPE_INTERNATIONAL = "INTERNATIONAL";

// Booking payment status constants
export const BOOKING_PAYMENT_STATUS_PENDING = "PENDING";
export const BOOKING_PAYMENT_STATUS_PAID = "PAID";
export const BOOKING_PAYMENT_STATUS_REFUNDED = "REFUNDED";

// Booking payment method constants
export const BOOKING_PAYMENT_METHOD_CASH = "CASH";
export const BOOKING_PAYMENT_METHOD_CARD = "CARD";
export const BOOKING_PAYMENT_METHOD_WALLET = "WALLET";

export const WALLET_MINIMUM_AMOUNT = 4999;

// NDC/GDS SC Amount for void and refund
export const VOID_SC_AMOUNT = 200;
export const REFUND_SC_AMOUNT = 500;

export const DEFAULT_SERVICE_INTL_CHARGE = 30;
export const DEFAULT_SERVICE_BUSINESS_INTL_CHARGE = 36;

// Passenger search type constants
export const RESIDENT_PASSENGER = "resident";
export const FOREIGN_PASSENGER = "Foreigner";
export const NON_DOCUMENT_HOLDER = "Non-Document-Holder";

// Credit Types
export const ONE_DAY_CREDIT = "oneDay";
export const ONE_WEEK_CREDIT = "oneWeek";
export const TWO_WEEK_CREDIT = "twoWeek";

export const DIRECT_PAYMENT = ["wallet", "credit", "kacha"];
export const BANK_PAYMENT = ["webirr_cbe", "amhara", "webirr_awash"];

export const GDS = "GDS";
export const NDC = "NDC";

export const PAYMENT_METHODS = {
  CASH: "cash",
  CARD: "card",
  WALLET: "wallet",
  CREDIT: "credit",
  KACHA: "kacha",
};

// export const CONSTANTS = {
//   // User role constants
//   USER_ROLES: {
//     SUPERAGENT: "superAgent",
//     AGENT: "agent",
//     ENTERPRISE: "enterprise",
//     EMPLOYEE: "employee",
//     MEMBER: "member",
//     FINANCE: "finance",
//   },
//   SUPER_ROLE: "superAgent" || "employee",
//   AGENT_ROLE: "agent" || "member",

//   // Trip type constants
//   TRIP_TYPES: {
//     ONE_WAY: "oneWay",
//     ROUND_TRIP: "roundTrip",
//     MULTI_CITY: "multiCity",
//   },

//   // System fee constants
//   SYSTEM_FEES: {
//     LOCAL: 50,
//     INTERNATIONAL: 330,
//   },

//   // Booking status constants
//   BOOKING_STATUSES: {
//     PENDING: "PENDING",
//     CONFIRMED: "CONFIRMED",
//     CANCELLED: "CANCELLED",
//     COMPLETED: "COMPLETED",
//   },

//   // Booking type constants
//   BOOKING_TYPES: {
//     LOCAL: "LOCAL",
//     INTERNATIONAL: "INTERNATIONAL",
//   },

//   // Booking payment status constants
//   BOOKING_PAYMENT_STATUSES: {
//     PENDING: "PENDING",
//     PAID: "PAID",
//     REFUNDED: "REFUNDED",
//   },

//   // Booking payment method constants
//   BOOKING_PAYMENT_METHODS: {
//     CASH: "CASH",
//     CARD: "CARD",
//     WALLET: "WALLET",
//   },

//   WALLET_MINIMUM_AMOUNT: 4999,

//   DEFAULT_SERVICE_CHARGES: {
//     INTL: 30,
//     BUSINESS_INTL: 36,
//   },

//   // Passenger search type constants
//   PASSENGER_TYPES: {
//     RESIDENT: "resident",
//     FOREIGNER: "Foreigner",
//     NON_DOCUMENT_HOLDER: "Non-Document-Holder",
//   },

//   // Credit Types
//   CREDIT_TYPES: {
//     ONE_DAY: "oneDay",
//     ONE_WEEK: "oneWeek",
//     TWO_WEEK: "twoWeek",
//   },
// };
